<template>
  <div class="landing_0223">
    <div class="topbar">
      <div class="container">
        <div class="brand">
          <router-link @click="onClickLink" to="/">
            <img src="/svgs/logo_3.svg" alt="logo" />
          </router-link>
        </div>

        <nav :class="{ active: navbarActive }">
          <ul>
            <li>
              <router-link @click="onClickLink" to="/campus/barcelona">
                Кампус в Барселоне
              </router-link>
            </li>
            <li>
              <router-link @click="onClickLink" to="/campus/madrid">
                Кампус в Мадриде
              </router-link>
            </li>
          </ul>
          <button class="close" @click="toggleNavbar(false)">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </nav>

        <button class="collapse" @click="toggleNavbar(true)">
          <i class="fa-solid fa-bars"></i>
        </button>
      </div>
    </div>

    <section>
      <div
        class="caption-wrapper img-container"
        style="background-image: url('/imgs/landing_1122/section_1.png')"
      >
        <div class="caption">
          <small>
            Центр, аккредитованный институтом Сервантеса и Торговой палатой
          </small>
          <img
            src="/imgs/landing_0223/logos_camara.png"
            alt="Logos"
            class="logos_camara"
          />

          <h1>
            <span class="text-accent">Получите студенческую</span> <br />
            резиденцию в школе <br />
            NL College
          </h1>

          <h4 class="mt-5 mb-6">
            аккредитованной институтом Сервантеса, <br />
            и живите в течение года в Мадриде
          </h4>

          <div class="images mb-5">
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/landing_0223/circle_01.png')"
            />
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/landing_0223/circle_02.png')"
            />
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/landing_0223/circle_03.png')"
            />
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/landing_0223/circle_04.png')"
            />
          </div>

          <p>Современные кампусы в Мадриде и Барселоне</p>

          <button class="btn btn-grad btn-ru-landing" @click="goToForm">
            Хочу узнать подробнее
          </button>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row py-7">
          <div class="col-md-8 text-center text-md-left">
            <i class="isologo mb-2"></i>
            <h4 class="text-accent-2 mb-4">
              Основная информация по Студенческой визе D или студенческой
              резиденции от 180 дней до 1 календарного года
            </h4>

            <p class="text-dark mb-4">
              Студенческая резиденция дает право официально работать от 20 до 30
              часов в неделю в любой сфере.
            </p>

            <button class="btn btn-grad-2 btn-ru-landing" @click="goToForm">
              Хочу узнать подробнее
            </button>
          </div>
          <div class="col-md-4 pt-5">
            <img
              src="/imgs/landing_0223/circle_05.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>

        <div
          class="card img-container"
          style="background-image: url('/imgs/landing_0223/rect_01.png')"
        >
          <div class="card-body text-white px-2 py-6 p-md-7">
            <h2>Как получить визу D</h2>
            <p><b> 2 варианта оформления студенческой резиденции</b></p>

            <div class="row justify-content-around">
              <div class="col-md-5 col-lg-4 mb-4 mb-md-0">
                <div
                  class="card card-white minh-3 h-100 d-flex justify-content-center align-items-center"
                >
                  <div class="card-body">
                    B визовом центре, консульстве или посольстве Испании в вашей
                    стране
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-lg-4">
                <div
                  class="card card-white minh-3 h-100 d-flex justify-content-center align-items-center"
                >
                  <div class="card-body">
                    Въехав по Шенгенской визе оформить напрямую в Испании через
                    Extranjería* (миграционная служба)
                  </div>
                </div>
              </div>
            </div>

            <small class="maxw-4 my-5 d-block mx-auto">
              <b>
                *на момент подачи документов на оформление студенческой
                резиденции, y вас должно быть минимум 30 календарных дней до
                окончания действия вашей Шенгенской визы
              </b>
            </small>

            <a
              href="/files/Student Visa Long-term-2.pdf"
              download="Student Visa Long-term-2.pdf"
              target="_blank"
              class="btn btn-white"
            >
              Скачать документ Студенческая виза
            </a>
          </div>
        </div>
      </div>
    </section>

    <section
      class="img-container py-7"
      style="background-image: url('/imgs/landing_0223/shape.png')"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-left">
            <h3 class="text-accent-2 mb-5">
              Пакет документов для оформления студенческой визы или резиденции
            </h3>
            <ul class="text-dark fw-600 mb-5">
              <li>Фотография</li>
              <li>Паспорт действительный на весь срок обучения</li>
              <li>
                Сертификат o зачислении в языковую школу (выдается NL college
                после подтверждения o полной оплате курса)
              </li>
              <li>
                Сертификат из банка c суммой из расчета минимум 600 € /месяц
              </li>
              <li>Мед. страховка</li>
              <li>Справка o несудимости c апостилем</li>
              <li>
                Медицинский сертификат, в соответствии c испанским законом o
                здравоохранении от 2005 r
              </li>
              <li>Подтверждение места жительства</li>
              <li>Оплата госпошлины на получение визы</li>
            </ul>
            <button
              class="btn btn-grad-2 text-center btn-ru-landing"
              @click="goToForm"
            >
              Хочу узнать подробнее
            </button>
          </div>
          <div class="col-md-4">
            <div class="face-images mt-5 mt-md-0">
              <div
                class="img-container img-container-circle"
                style="
                  background-image: url('/imgs/landing_0223/circle_06.png');
                "
              />
              <div
                class="img-container img-container-circle"
                style="
                  background-image: url('/imgs/landing_0223/circle_07.png');
                "
              />
              <div
                class="img-container img-container-circle"
                style="
                  background-image: url('/imgs/landing_0223/circle_08.png');
                "
              />

              <img
                src="/imgs/landing_0223/flag.png"
                class="flag"
                alt="ru-flag"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="img-container text-white py-7 d-flex flex-column align-items-center"
      style="background-image: url('/imgs/landing_0223/rect_02.png')"
    >
      <i class="isologo mb-2"></i>
      <h2 class="mb-4">Как получить визу D</h2>
      <h4 class="mb-5">Acelerado course - Интенсивный курс</h4>
      <p class="maxw-5">
        Для того что бы подать на визу, курс должен быть длительностью от 7 до
        12 месяцев, c минимальной нагрузкой 20 часов/неделя, по 4 часа в день c
        понедельника по пятницу.
      </p>
    </section>

    <section>
      <div class="container py-7">
        <div class="row">
          <div class="col-md-6 d-flex flex-column align-items-center">
            <i class="isologo mb-2" />
            <h3 class="text-accent-2 mb-5">Проживание</h3>
            <div
              class="img-container img-container-circle mb-5"
              style="background-image: url('/imgs/landing_0223/circle_09.png')"
            />
            <p>
              Наши партнеры предлагают проживание как в Мадриде так и в
              Барселоне, на любой вкус и кошелек.
            </p>
            <p>
              Цены варьируются от 450€ до 1080€ (комната на
              двоих/индивидуальная/апартаменты).
            </p>

            <a
              href="/files/Accommodation (ENG).pdf"
              download="Accommodation (ENG).pdf"
              target="_blank"
              class="btn mb-5 mb-md-0"
            >
              B документе находитсяинформация o проживании
            </a>
          </div>
          <div class="col-md-6 d-flex flex-column align-items-center">
            <i class="isologo mb-2" />
            <h3 class="text-accent-2 mb-5">Каникулы</h3>
            <div
              class="img-container img-container-circle mb-5"
              style="background-image: url('/imgs/landing_0223/circle_10.png')"
            />
            <p>Возьмите каникулы в любое удобное вам время.</p>
            <p>
              B наших долгосрочных курсах мы предлагаем студентам возможность
              взять каникулы в любой период обучения, помимо основных
              праздничных дней устанавливаемых государством.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="img-container text-white py-7"
      style="background-image: url('/imgs/landing_0223/rect_03.png')"
    >
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-9 col-sm-6 col-lg-3">
            <div class="card card-white">
              <div
                class="card-body img-container contain"
                style="background-image: url('/imgs/landing_0223/icon_01.png')"
              >
                <p>Начните новую жизнь в Испании</p>
              </div>
            </div>
          </div>
          <div class="col-9 col-sm-6 col-lg-3">
            <div class="card card-white">
              <div
                class="card-body img-container contain"
                style="background-image: url('/imgs/landing_0223/icon_02.png')"
              >
                <p>Приезжайте в Мадрид с долгосрочной визой</p>
              </div>
            </div>
          </div>
          <div class="col-9 col-sm-6 col-lg-3">
            <div class="card card-white">
              <div
                class="card-body img-container contain"
                style="background-image: url('/imgs/landing_0223/icon_03.png')"
              >
                <p>Предложение жилья от наших партнеров</p>
              </div>
            </div>
          </div>
          <div class="col-9 col-sm-6 col-lg-3">
            <div class="card card-white">
              <div
                class="card-body img-container contain"
                style="background-image: url('/imgs/landing_0223/icon_04.png')"
              >
                <p>
                  Регистрация, книги и доп. мероприятия включены в стоимость в
                  зависимости от пакета
                </p>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-grad btn-ru-landing" @click="goToForm">
          Хочу узнать подробнее
        </button>
      </div>
    </section>

    <section class="footer">
      <h2 class="mb-5">
        <span class="text-accent">Заполните анкету в вашу</span> <br />
        новую жизнь <span class="text-accent">в Испании</span>
      </h2>

      <form class="pt-4" id="form" action="#form" @submit="onSubmit">
        <input
          type="text"
          placeholder="Ваше имя"
          required
          name="your-name"
          v-model="form.name"
        />
        <input
          type="text"
          placeholder="Ваш email"
          required
          name="your-email"
          v-model="form.email"
        />
        <input
          type="text"
          placeholder="Ваш номер Whatsapp или Telegram"
          required
          name="your-phone"
          v-model="form.phone"
        />

        <button type="submit" class="btn btn-sm btn-block btn-primary">
          Отправить
        </button>
      </form>

      <router-link @click="onClickLink" to="/"
        ><img src="/svgs/logo_2.svg" alt="" class="logo_2 mb-5"
      /></router-link>

      <p class="mb-2">
        <router-link to="/campus/barcelona">Кампус в Барселоне</router-link> |
        <router-link to="/campus/madrid">Кампус в Мадриде</router-link>
      </p>
      <a class="mb-5" href="mailto:info@nlcollege.es"> info@nlcollege.es </a>

      <div class="socials">
        <a href=""><i class="fa-brands fa-instagram"></i></a>
        <a href=""><i class="fa-brands fa-facebook"></i></a>
        <a href=""><i class="fa-brands fa-tiktok"></i></a>
        <a href=""><i class="fa-brands fa-linkedin"></i></a>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
// import { createPmlScript, removePmlScript } from "@/utils/pmlHelper.js";

export default {
  setup() {
    let navbarActive = ref(false);

    const toggleNavbar = (active) => {
      navbarActive.value = active;
    };
    const onClickLink = () => {
      if (navbarActive.value) navbarActive.value = false;
    };

    return {
      navbarActive,
      toggleNavbar,
      onClickLink,
    };
  },
  // beforeUnmount() {
  //   removePmlScript();
  // },
  // mounted() {
  //   createPmlScript("russia");
  // },
  data: () => ({
    form: {
      name: null,
      email: null,
      phone: null,
    },
  }),
  methods: {
    goToForm() {
      document.querySelector("#form").scrollIntoView();
    },
    onSubmit(e) {
      e.preventDefault();

      axios.get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
        params: {
          "your-phone": this.form.phone,
          "your-email": this.form.email,
          "your-name": this.form.name,
          token: "r990ol2079escefe1195y9546942y5qns",
        },
      });

      this.clearForm();
      this.formSuccessMessage();
    },
    clearForm() {
      this.form = {
        name: null,
        email: null,
        phone: null,
      };
    },
    formSuccessMessage() {
      this.$toast.success("Большое спасибо, ваше сообщение отправлено");
    },
  },
};
</script>
